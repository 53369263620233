*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    font-weight: 700;
    font-family: 'Rowdies', sans-serif;
}


.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.section {
font-size: 40px;
color: black;
margin-top: 10%;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
position: absolute;
z-index: 1;
text-align: center;
} 

#headline {
    letter-spacing: 0.12rem;
}



#modal {
    visibility: hidden;
}


#video {
    width: 320;
    height: 240;
    display: block;
    position: absolute;
}


.form {
    position: absolute;
    width: 100vw;
    display: flex;
    justify-content: center;
    z-index: 3;
    overflow-y: scroll;
    height: 100vh;
    background-color: black;
}

.modalTop {
    position: absolute;
}

#info {
    margin-top: 12px;
font-weight: 300;
 font-size: 20px;
 margin-left: 10%;
 margin-right: 10%;
}



.bottom-section {
    font-size: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: center;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom:10px;
    z-index: 2;
    margin-bottom: 5%;
    }
/* 
    .bottom-section > * {
        flex: 1 1 calc(350px + 1em);
    } */


    @media only screen and (max-width: 600px) {
        .bottom-section {
          flex-direction: column;
        }

        button {
            padding: 15px 20px;
            font-size: 15;
        }
        
      }

    button {
        background-color: #000000; /* Green */
        border: none;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 8px;
        font-weight: 700;
        border-radius: 4px;
        font-family: 'Rowdies', sans-serif;
        font-weight: 300;
        touch-action: manipulation;
        /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); */
      }

    
      #partyButton {
display: none;
      }

      a {
        color: black;
      }
      